// Handler when the DOM is fully loaded.
var callback = function() {
  var inputs = document.querySelectorAll('input');

  inputs.forEach((input) => {
    input.addEventListener('keydown', (e) => {
      if (e.target.value.length >= 1) {
        e.target.classList.add('filled');
      } else {
        e.target.classList.remove('filled');
      }
    })
  });
}

if (
  document.readyState === 'complete' || 
  (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
  callback();
} else {
  document.addEventListener('DOMContentLoaded', callback);
}

var toggleOptions = function(event) {
  var x = document.getElementById("options");
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
  event.preventDefault(); 
}